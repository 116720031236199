import { graphql, Link } from 'gatsby';
import React from 'react';
import Layout from '../components/public/layout';
import Newspanel from '../components/public/newsPanel'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from "@contentful/rich-text-types"
import { Helmet } from 'react-helmet';
export const query = graphql`
    query($slug: String!) {
        contentfulArtykulyBiezace(linkName: {eq: $slug}){
            linkName
            title
            publishDate(formatString: "D MMMM YYYY", locale: "pl")
            author
            content {
                raw
                references{
                    contentful_id
                    title
                  	file {
                      url
                    }
                }
            }
        	mainPhoto {
              file {
                url
              }
            }
        }
    }
`

const Articlecurrent = (props) => {
    const assets = new Map(props.data.contentfulArtykulyBiezace.content.references.map(ref => [ref.contentful_id, ref]))
    const options = {
        renderText: text => text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
        renderNode:{
            [BLOCKS.EMBEDDED_ASSET]: node => {
                const url = assets.get(node.data.target.sys.id).file.url
                const alt = assets.get(node.data.target.sys.id).title
                return <img alt={alt} src={url} style={{width: '100%'}}/>
            }
        }
    }
    return (
        <Layout>
            <Helmet title={`${props.data.contentfulArtykulyBiezace.title} | Parafia w Starych Skoszewach`}/>
            <div className="container">
                <div className="left-col">
                    <div className="article-without-photo">
                        <span className='where'>
                            <Link to='/'>Strona Główna</Link>
                            <span className='where-arrow'>&gt;</span>
                            <Link to='/aktualnosci'>Aktualności</Link>
                            <span className='where-arrow'>&gt;</span>
                            <Link to='/artykuly'>Artykuły</Link>
                        </span>
                        <h3>{props.data.contentfulArtykulyBiezace.title}</h3>
                        <div className="line">
                            <span>{props.data.contentfulArtykulyBiezace.publishDate}</span>
                            <span>Autor: {props.data.contentfulArtykulyBiezace.author}</span>
                        </div>
                        {
                            props.data.contentfulArtykulyBiezace.mainPhoto != null ?
                                <img src={props.data.contentfulArtykulyBiezace.mainPhoto.file.url} alt="article" style={{ width: 'calc(100% + 2em)', margin: '0 0 0 -1em' }} /> :
                                null
                        }
                        <div style={{color: '#111111', textAlign: 'justify' }}>
                            {documentToReactComponents(JSON.parse(props.data.contentfulArtykulyBiezace.content.raw), options)}
                        </div>
                    </div>
                </div>
                <div className="right-col">
                    <Newspanel currArticle={props.data.contentfulArtykulyBiezace.linkName}/>
                    {/* <div className="facebook-panel"></div> */}
                </div>
            </div>
        </Layout>
    );
}

export default Articlecurrent

